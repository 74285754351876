<template lang="pug">
.page.question
  .content(:class='{ show: showBody }')
    //- .swiper-pagination
    .idx
      span {{ currentIdx + 1 }}
      |
      | / {{ questionsData.length }}
    .group {{ currentQuestion && currentQuestion.groupTitle }}

    .save_progress(@click='saveProgress') 保存进度

    .swiper-container(ref='swiperRef')
      .swiper-wrapper
        .swiper-slide(v-for='item in questionsData', :key='item.id')
          .title(data-swiper-parallax='0') {{ item.content }}
          .as_list(v-if='item.answerList && item.answerList.length')
            .as(v-for='(answer, i) in item.answerList', :data-swiper-parallax='String(i * -150)', :class='{ active: item.answerSelectedList.indexOf(answer.id) > -1 }', @click='selectAs(answer.id)') {{ answer.tag }}、{{ answer.answer }}

    .btns
      .btn(@click='prev') {{ currentIdx == 0 ? "重新开始" : "上一题" }}
      .btn.blue(@click='next', :class='{"disabled": !canNext}') {{ currentIdx < questionsData.length - 1 ? "下一题" : "提交查看结果" }}
</template>

<script>
import { getQueryString, scrollToTop } from '../assets/js/utils'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'Question',
  data() {
    return {
      currentIdx: 0,
      canNext: true,
      _swiper: Object,
      showBody: false
    }
  },
  computed: {
    questionsData() {
      return this.$store.state.questionsData
    },
    currentQuestion() {
      return this.$store.state.questionsData[this.currentIdx]
    }
  },
  methods: {
    saveProgress() {
      this.$alert('进度已保存，下次访问可以继续答题')
    },
    async selectAs(id) {
      let index = this.currentQuestion.answerSelectedList.indexOf(id)
      this.currentQuestion.answerSelectedList.splice(0)
      if (index === -1) {
        this.currentQuestion.answerSelectedList.splice(0)
        this.currentQuestion.answerSelectedList.push(id)
      }
      this.$store.commit('SET_QUESTIONS_DATA', this.questionsData)
      this.$selectAudio.play()
      await this.next()
    },
    async next() {
      if (!this.canNext) return
      if (!this.currentQuestion.answerSelectedList || !this.currentQuestion.answerSelectedList.length) {
        this.$toast('请选择选项')
        return
      }
      this.canNext = false

      this._swiper.slideNext()
      scrollToTop()

      if (this.currentIdx < this.questionsData.length - 1) {
        this.$nextAudio.play()
      }

      const _this = this
      try {
        await this.$api.putQuizze(this.$store.state.currentQuizze.id, {
          questionID: this.currentQuestion.id,
          answerIDList: this.currentQuestion.answerSelectedList
        })
      } catch (e) {
        // 有可能没有答题id
        this.$alert({
          msg: '选项提交失败，请稍后再试',
          oneBtn: true,
          enterName: '我知道了',
          nextFn: e => {
            _this.canNext = true
            _this._swiper.slidePrev()

            this.$nextAudio.play()
          }
        })
        return
      }
      if (this.currentIdx === this.questionsData.length - 1) {
        this.$alert({
          msg: '确认提交选项？',
          oneBtn: false,
          enterName: '提交',
          nextFn: async e => {
            if (e) {
              try {
                await this.$api.putQuizzeFinished(this.$store.state.currentQuizze.id)
                this.$finishedAudio.play()
                // 清除进度中的答题记录
                this.$removeStore('myAnswersCurrentProgress')
                this.$router.replace('/result')
                this.$loading.hide()
              } catch (e) {
                this.canNext = true
              }
            } else {
              this.canNext = true
            }
          }
        })
        return
      } else {
        // 保存答题进度
        this.$setStore('myAnswersCurrentProgress', {
          currentQuizzeID: this.$store.state.currentQuizze.id,
          questions: this.questionsData,
          currentIdx: this.currentIdx + 1
        })
      }
      this.canNext = true
      this.currentIdx = this._swiper.activeIndex
    },
    async prev() {
      if (this.currentIdx > 0) {
        this._swiper.slidePrev()

        this.$nextAudio.play()
        this.currentIdx = this._swiper.activeIndex
        scrollToTop()
      } else {
        this.$removeStore('myAnswersCurrentProgress')
        this.$router.push('/')
      }
    },
    initSwiper() {
      this._swiper = new Swiper(this.$refs.swiperRef, {
        autoHeight: true,
        autoplay: false,
        allowTouchMove: false,
        loop: false,
        speed: 400,
        parallax: true,
        spaceBetween: 30
        // pagination: {
        //   el: '.swiper-pagination',
        //   type: 'progressbar',
        // },
      })
      // 获取到历史进度
      const myAnswersCurrentProgress = this.$getStore('myAnswersCurrentProgress')

      if (myAnswersCurrentProgress.currentQuizzeID && myAnswersCurrentProgress.currentIdx) {
        this.$store.commit('CURRENT_QUIZZE', {
          id: myAnswersCurrentProgress.currentQuizzeID,
          isFinnish: false
        })
        this._swiper.slideTo(myAnswersCurrentProgress.currentIdx)

        this.$nextAudio.play()
        // 滚到继续答题位置
        this.currentIdx = this._swiper.activeIndex
        // 存储题目解析
        for (let o of myAnswersCurrentProgress.questions) {
          if (o.answerSelectedList && o.answerSelectedList.length) {
            for (let q of this.questionsData) {
              if (o.id == q.id) {
                q.answerSelectedList = o.answerSelectedList
                break
              }
            }
          }
        }
        this.$store.commit('SET_QUESTIONS_DATA', this.questionsData)
      }

      this.showBody = true
      this.$loading.hide()
    }
  },
  async mounted() {
    this.$loading.show()
    if (this.$store.state.currentQuizze.id && (!this.$store.state.questionsData || !this.$store.state.questionsData.length)) {
      await this.$store.dispatch('getQuizzesQuestions')
    } else if (!this.$store.state.currentQuizze.id) {
      await this.$store.dispatch('startQuizze')
    }

    const init = () => {
      if (this.questionsData && this.questionsData.length) {
        this.initSwiper()
      } else {
        setTimeout(() => {
          init()
        }, 30)
      }
    }
    this.$nextTick(() => {
      init()
    })

    const ready = () => {
      this.$regWeChat()
      const code = getQueryString('code')
      if ((!this.$store.state.userInfo.openID || !this.$store.state.userToken.accessToken) && !code) {
        window.location.href.indexOf('/login') < 0 && this.$setExpireStore('fromPath', window.location.href)
        const url = process.env.VUE_APP_API_URL
        window.location.replace(`${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_userinfo&redirectURI=${encodeURIComponent(window.location.href)}`)
      }
    }

    if (this.$store.state.isWeixin && (!window.WeixinJSBridge || !WeixinJSBridge.invoke)) {
      document.addEventListener('WeixinJSBridgeReady', ready, false)
    } else {
      ready()
    }
  }
}
</script>

<style lang="stylus" scope>
.question
  background: #0563DA
  background-size: cover
  padding-top: 1.5rem

  .content
    background: #fff
    padding: 0.44rem 0.32rem
    padding-bottom: calc(constant(safe-area-inset-bottom) / 2 + 0.6rem)
    padding-bottom: calc(env(safe-area-inset-bottom) / 2 + 0.6rem)
    margin: 0 0.16rem
    border-radius: 0.4rem 0.4rem 0 0
    transition: all 0.3s ease
    position: relative
    box-sizing: border-box
    min-height: calc(100vh - 1.5rem)
    transform: translateY(120%)

    .swiper-pagination
      position: absolute
      top: -1.2rem
      left: 0
      box-sizing: border-box
      width: 100%
      height: 0.2rem
      border-radius: 0.15rem
      overflow: hidden

    &.show
      transform: translateY(0)

    .idx
      color: #333333
      font-size: 0.36rem
      line-height: 1
      font-weight: bold

      span
        font-size: 0.6rem

    .group
      opacity: 0.42
      font-size: 0.7rem
      line-height: 1
      color: #FFFFFF
      display: inline-block
      position: absolute
      right: 0.1rem
      top: -0.64rem

    .save_progress
      font-size: 0.24rem
      color: #0563DA
      text-align: center
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      right: 0.32rem
      top: 0.55rem
      background: #FFFFFF
      border: 1px solid #0563DA
      border-radius: 0.36rem
      padding: 0.05rem 0.2rem

    .swiper-container
      width: 100%
      height: auto
      overflow: hidden
      margin: 0 -0.32rem
      padding: 0 0.32rem
      box-sizing: content-box

    .title
      font-size: 0.36rem
      color: #333333
      font-weight: bold
      line-height: 1.6
      margin-top: 0.3rem

    .as_list
      margin-top: 0.4rem

      .as
        background: #F5F5F5
        border-radius: 0.64rem
        font-size: 0.3rem
        color: #221E1E
        line-height: 1.7
        margin-top: 0.28rem
        padding: 0.3rem 0.6rem
        transition: all 0.2s ease

        &.active
          color: #fff
          background: #0B73F7
          box-shadow: 0 0.04rem 0.14rem 0 rgba(11, 115, 247, 0.43)
          border-radius: 0.64rem

  .btns
    width: 100%
    margin: 0 auto
    display: flex
    flex-flow: row no-wrap
    align-items: center
    justify-content: space-between
    margin-top: 0.45rem

    .btn
      background: #FFFFFF
      border: 1px solid #979797
      border-radius: 0.5rem
      height: 1rem
      width: 3.05rem
      font-size: 0.36rem
      color: #999999
      display: flex
      flex-flow: row no-wrap
      align-items: center
      justify-content: center

      &.disabled
        opacity: 0.6
        pointer-events: none

      &.blue
        color: #fff
        border-color: #0563DA
        background: #0563DA
</style>
